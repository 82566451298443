// Store mutations

import VueCookie from 'vue-cookies';

export const mutations = {
  saveUserToken(state, data) {
    VueCookie.set('token_ort_core', data.token, state.expiry.cookie);
    state.api.token = data.token;
    state.api.csrftoken = VueCookie.get('csrftoken');
  },
  saveUserInfos(state, logInfos) {
    state.email = logInfos.email;
    state.id = logInfos.id; // deprecated. use jwt payload instead
    state.name = `${logInfos.first_name} ${logInfos.last_name}`;
    state.profile_image = logInfos.profile_image;
    state.groups = logInfos.groups;
    state.cache_data = localStorage.getItem('cachedata');
    state.section_focused = localStorage.getItem('section_focused');
  },
  removeUserInfos(state) {
    state.email = null;
    state.name = null;
    state.username = null;
    state.groups = null;
    state.api.token = null;
    state.api.csrftoken = null;
    VueCookie.remove('csrftoken');
    VueCookie.remove('token_ort_core');
    state.section_focused = null;
  },
  setLoggedIn(state, logged_in) {
    state.logged_in = logged_in;
  },
  setFocusedSection(state, section) {
    state.section_focused = section;
    localStorage.setItem('section_focused', state.section_focused, state.expiry.local_storage);
  },
  cacheData(state, data) {
    if (state.cache_data === null) {
      state.cache_data = {};
    }
    state.cache_data[data.index] = data.data;
    localStorage.setItem('cachedata', this.state.cache_data, state.expiry.local_storage);
  },
  changeAlertInfos(state, alert) {
    state.alert.show = alert.show;
    state.alert.type = alert.type;
    state.alert.message = alert.message;
  },

};
