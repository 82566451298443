import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils.js';

const state = {
  totalInvestments: null,
  investments: [],
  articles: [],
  articlesLoading: null,
  webinar: {},
  thumbnail: [],
  webinarLoading: false,
  fundraisingExitStatusValue: '',
  fundraisingExitStatusLoading: false,
  exitStatusChoices: [],
  fundraising: {},
  fundraisingStartup: {},
  fundraisingStatusChoices: [],
  fundraisingGrowthStageChoices: [],
  fundraisingCurrencyChoices: [],
  investmentStatusChoices: [{ text: 'All status', value: null }],
  investmentFeesTypeChoices: [],
};

const getters = {
  getTotalInvestments: (state) => state.totalInvestments,
  getArticles: (state) => state.articles,
  getFundraisingInvestments: (state) => state.investments,
  isArticlesLoading: (state) => state.articlesLoading,
  isArticlesFull: (state) => state.articles.length >= 5,
  getWebinar: (state) => state.webinar,
  getThumbnail: (state) => state.thumbnail,
  isWebinarLoading: (state) => state.webinarLoading,
  getExitStatusChoices: (state) => state.exitStatusChoices,
  getExitStatusChoicesMap: (state) => {
    const map = {};
    state.exitStatusChoices.forEach((item) => {
      map[item.value] = item.text;
    });
    return map;
  },
  getFundraisingExitStatusValue: (state) => state.fundraisingExitStatusValue,
  isFundraisingExitStatusLoading: (state) => state.fundraisingExitStatusLoading,
  getFundraising: (state) => state.fundraising,
  getFundraisingStartup: (state) => state.fundraisingStartup,
  getFundraisingGrowthStageChoices: (state) => state.fundraisingGrowthStageChoices,
  getFundraisingCurrencyChoices: (state) => state.fundraisingCurrencyChoices,
  getInvestmentFeesTypeChoices: (state) => state.investmentFeesTypeChoices,
};

const actions = {
  async addArticle({ commit }, body) {
    commit('setArticleLoading', true);
    try {
      const response = await axiosCore.post(`fundraisings/${body.fundraising_id}/articles`, body);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setArticleLoading', null);
    }
  },
  async updateArticle({ commit }, body) {
    commit('setArticleLoading', true);
    try {
      const response = await axiosCore.patch(`fundraisings/${body.fundraising_id}/articles/${body.article_id}`, body);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setArticleLoading', null);
    }
  },
  async fetchFundraisingArticles({ commit }, fundraisingId) {
    commit('setArticleLoading', true);
    try {
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/articles`);
      commit('setArticles', response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setArticleLoading', null);
    }
  },
  async deleteArticle({ commit }, body) {
    commit('setArticleLoading', true);
    try {
      const response = await axiosCore.delete(`fundraisings/${body.fundraising_id}/articles/${body.article_id}`);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setArticleLoading', null);
    }
  },
  async fetchFundraisingWebinar({ commit }, fundraisingId) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/webinars`);
      commit('setWebinar', response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
  async updateWebinar({ commit }, { id, sendBody }) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.patch(`fundraisings/${id.id}/webinars/${sendBody.id}`, sendBody);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
  async updateThumbnail({ commit }, body) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.post(`files/image/webinars/${body.webinar_id}/thumbnail/`, body);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
  async fetchThumbnail({ commit }, body) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.get(`files/image/webinars/${body}/thumbnail/`);
      commit('setThumbnail', response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
  async deleteThumbnail({ commit }, webinarId) {
    commit('setWebinarLoading', true);
    try {
      const response = await axiosCore.delete(`files/image/webinars/${webinarId}/thumbnail/`);
      commit('setThumbnail', response);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setWebinarLoading', false);
    }
  },
  async getFundraisingExitStatus({ commit, dispatch }, fundraisingId) {
    commit('setFundraisingExitStatusLoading', true);
    try {
      const response = await axiosCore.get(`fundraisings/${fundraisingId}/exit-status`);
      commit('setFundraisingExitStatusValue', response.data.exit_status);
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setFundraisingExitStatusLoading', false);
    }
  },
  async updateFundraisingExitStatus({ commit, dispatch }, { fundraisingId, exitStatusValueChoice }) {
    commit('setFundraisingExitStatusLoading', true);
    try {
      const response = await axiosCore.patch(`fundraisings/${fundraisingId}/exit-status`, { exit_status: exitStatusValueChoice });
      commit('setFundraisingExitStatusValue', response.data.exit_status);
      dispatch('triggerAlert', {
        show: true,
        type: 'success',
        message: 'The Exit Status has been updated.',
      });
    } catch (error) {
      dispatch('triggerAlert', {
        show: true,
        type: 'danger',
        message: error.message,
      });
    } finally {
      commit('setFundraisingExitStatusLoading', false);
    }
  },
  async fetchInvestments({ commit }, {
    fundraisingId, page = 1, page_size = 5, status = null,
  }) {
    try {
      const fundraisingInvestmentsQuery = `/fundraisings/${fundraisingId}/investments`;
      const investmentResponse = await axiosCore(fundraisingInvestmentsQuery);
      commit('setInvestments', investmentResponse.data);
      commit('setTotalInvestments', investmentResponse.data.length);
    } catch (error) {
      console.error('Error fetching investments:', error);
    }
  },
  async exportFundraisingInvestmentsTable({ dispatch }, { fundraisingId }) {
    try {
      const response = await axiosCore.get(`/fundraisings/${fundraisingId}/investments?format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      errorAlert('There was an error when exporting Fundraising Investments', error);
    }
  },
  async fetchFundraising({ commit }, { fundraisingId, investmentStatus }) {
    let query = `/fundraisings/${fundraisingId}/`;
    if (investmentStatus != null) {
      query = `${query}?investments_status=${investmentStatus}`;
    }
    try {
      const fundraisingResponse = await axiosCore(query);
      commit('setFundraising', fundraisingResponse.data);
      const fundraisingStartupResponse = await axiosCore(`/startups/${fundraisingResponse.data.startup}/?fields=name,is_fund`);
      commit('setFundraisingStartup', fundraisingStartupResponse.data);
      commit('setInvestmentsStartup', fundraisingStartupResponse);
    } catch (err) {
      console.error('Error fetch fundraising', err);
    }
  },
  async fetchFundraisingOptions({ commit }) {
    try {
      const statusChoices = await axiosCore('choices/?choices=fundraising_status_choices');
      commit('setFundraisingStatusChoices', statusChoices.data);
      const growthStagesChoices = await axiosCore('growths/');
      commit('setGrowthStageChoices', growthStagesChoices.data);
      const currencyChoices = await axiosCore('currencies/');
      commit('setFundraisingCurrencyChoices', currencyChoices.data);
    } catch (err) {
      console.error('Error fetching status choices:', err);
    }
  },
  async fetchInvestmentSelects({ commit }) {
    try {
      const investmentStatusChoices = await axiosCore('choices/?choices=investment_status_choices');
      commit('setInvestmentStatusChoices', investmentStatusChoices.data);
      const investmentFeesTypeChoices = await axiosCore('choices/?choices=InvestmentFeesTypeChoices');
      commit('setInvestmentFeesTypeChoices', investmentFeesTypeChoices.data);
    } catch (err) {
      console.error(err);
    }
  },
};

const mutations = {
  setTotalInvestments: (state, totalInvestments) => (state.totalInvestments = totalInvestments),
  setArticleLoading: (state, loading) => (state.articlesLoading = loading),
  setArticles: (state, data) => (state.articles = data),
  setWebinar: (state, data) => (state.webinar = data),
  setWebinarLoading: (state, loading) => (state.webinarLoading = loading),
  setThumbnail: (state, data) => (state.thumbnail = data),
  setExitStatusChoices: (state, data) => { state.exitStatusChoices = data; },
  setFundraisingExitStatusValue: (state, data) => (state.fundraisingExitStatusValue = data),
  setFundraisingExitStatusLoading: (state, loading) => (state.fundraisingExitStatusLoading = loading),
  setInvestments(state, newInvestments) { state.investments = newInvestments; },
  setFundraising(state, fundraising) { state.fundraising = fundraising; },
  setFundraisingStartup(state, fundraisingStartup) { state.fundraisingStartup = fundraisingStartup; },
  setInvestmentsStartup(state, startupResponse) {
    state.investments.forEach((investment, i) => {
      investment.fundraising.startup = startupResponse.data;
    });
  },
  setFundraisingStatusChoices(state, choices) { state.fundraisingStatusChoices = choices; },
  setGrowthStageChoices(state, choices) {
    choices.forEach((growths) => {
      state.fundraisingGrowthStageChoices.push({ value: growths.pk, text: growths.name });
    });
  },
  setFundraisingCurrencyChoices(state, currencies) {
    currencies.forEach((currency) => {
      state.fundraisingCurrencyChoices.push({ value: currency.id, text: currency.name });
    });
  },
  setInvestmentStatusChoices(state, statusChoices) { state.investmentStatusChoices = [...state.investmentStatusChoices, ...statusChoices]; },
  setInvestmentFeesTypeChoices(state, feesTypeChoices) { state.investmentFeesTypeChoices = feesTypeChoices; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
