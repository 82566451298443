<template>
  <div id="app-login" v-if="!getters.isAuthenticated">
    <alert
      v-if="this.$store.state.alert.show"
      :message="this.$store.state.alert.message"
      :type="this.$store.state.alert.type"
    />
    <router-view />
  </div>
  <div id="app" v-else-if="getters.isAuthenticated">
    <navbar id="navbar" />
    <alert
      v-if="this.$store.state.alert.show"
      :message="this.$store.state.alert.message"
      :type="this.$store.state.alert.type"
    />
    <div class="view">
      <div class="col-12 col-lg side d-none d-lg-block">
        <sidebar></sidebar>
      </div>
      <div class="col-12 main">
        <div class="main-container px-0">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Getters} JwtGetters */

import { getGetter } from '@/utils/jsdoc.js';
import Navbar from '@/components/utils/Navbar.vue';
import Sidebar from '@/components/utils/Sidebar.vue';

import '@/assets/images/favicon.png';
import '@/assets/images/icon_ipad_logoCore_favicon.png';
import '@/assets/images/iphone_retina_logoCore_favicon.png';
import '@/assets/images/ipad_retina_logoCore_favicon.png';

export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar,
  },
  computed: {
    getters() {
      return {
        isAuthenticated: getGetter(/** @type {JwtGetters["isAuthenticated"]} */ ('isAuthenticated')),
      };
    },
  },
  beforeCreate() {
    document.title = 'OneRagtime | Core';
    if (!(this.$route.name === 'Login' && this.$route.query.logout)) {
      this.$store.dispatch('searchInfos');
    }
  },
};
</script>

<style lang="scss">

html,
body {
  font: $main-font-regular;
  color: $black-grey-color;
  font-size: 16px;
}

.container {
  @media (min-width: 576px) {
    max-width: 100vw !important;
  }
  @media (min-width: 768px) {
    max-width: 100vw !important;
  }
  @media (min-width: 992px) {
    max-width: 100vw !important;
  }
  @media (min-width: 1200px) {
    max-width: 100vw !important;
  }
  @media (min-width: 1600px) {
    max-width: 100vw !important;
  }
}

.view {
  display: flex;
  flex-wrap: wrap;
  background-color: $white-color !important;
  z-index: 10;
  .side {
    height: 100%;
    max-width: 180px;
    position: fixed;
    z-index: 2;
    padding: 0px;
    background-color: $white-color;
  }
  .main {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    .main-container {
      max-width: 100%;
      padding: 0px;
      min-height: 100vh;
      @media screen and (min-width: 992px) {
        padding-left: 180px !important;
      }
    }
  }
}

.btn {
  height: 35px;
  cursor: pointer;
  &-link:hover {
    text-decoration: none !important;
  }
}

a.button {
  box-shadow: none !important;
  padding-top: 15px;
}

.btn-group {
  .btn {
    cursor: pointer;
  }
}

.primary-button {
  background-color: $red-color !important;
  &:hover {
    background-color: $red-darker-color !important;
  }
  &:focus,
  &-focused {
    box-shadow: none !important;
    background-color: $red-darker-color !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.success-button {
  background-color: $green-color !important;
  &:hover {
    background-color: $green-darker-color !important;
  }
  &:focus {
    box-shadow: none !important;
    background-color: $green-darker-color !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.info-button {
  background-color: $blue-color !important;
  &:hover {
    background-color: $blue-darker-color !important;
  }
  &:focus,
  &-focused {
    box-shadow: none !important;
    background-color: $blue-darker-color !important;
  }
  .router-link-active {
    font: $main-font-regular;
    color: $black-grey-color;
    line-height: 1.5;
    cursor: pointer;
    text-decoration: underline;
    &:active {
      box-shadow: none !important;
    }
  }
}
.warning-button {
  background-color: $yellow-color !important;
  &:hover {
    background-color: $yellow-darker-color !important;
  }
  &:focus,
  &-focused {
    box-shadow: none !important;
    background-color: $yellow-darker-color !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.interaction-button {
  background-color: $purple-color !important;
  &:hover {
    background-color: $purple-darker-color !important;
  }
  &:focus,
  &-focused {
    box-shadow: none !important;
    background-color: $purple-darker-color !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

.router-link-active {
  font: $main-font-regular;
  color: $black-grey-color;
  line-height: 1.5;
  color: $blue-color;
  cursor: pointer;
  text-decoration: underline;
}

.router-link-active:hover {
  color: $blue-darker-color;
}

h1 {
  font: $main-font-bold;
  font-size: $xlarge-font-size;
  color: $black-color;
}

h2 {
  font: $main-font-bold;
  font-size: $large-font-size;
  color: $black-color;
}

h3 {
  font: $main-font-semibold;
  font-size: $large-font-size;
  color: $black-color;
}

h4 {
  font: $main-font-semibold;
  font-size: $slarge-font-size;
  color: $black-grey-color;
}

h5 {
  font: $main-font-semibold;
  font-size: $medium-font-size;
  color: $black-grey-color;
}

.form-group.form-validation {
  margin-bottom: 0px;
}

.custom-checkbox {
  color: $black-grey-color;
  vertical-align: middle;
  cursor: pointer;
}

.small-input.form-control {
  height: 25px !important;
  font-size: $small-font-size;
}

.multiselect {
  min-height: 35px !important;
  margin-top: 5px;
  color: $black-color !important;
  .multiselect__tags {
    border-radius: 5px;
    background: #ffffff;
    border: solid 1px #cccccc;
    font-size: $medium-font-size;
    color: $black-color !important;
    .multiselect__input,
    .multiselect__single {
      font: $main-font-regular !important;
      color: $black-color !important;
      font-weight: 400 !important;
      display: initial;
      line-height: 22px !important;
      &::placeholder {
        color: $black-color !important;
        font-weight: 400;
      }
    }
  }
  .multiselect__select::before {
    border-color: $black-color transparent transparent;
    border-width: 4px 4px 0;
    right: -6px;
  }
  .multiselect__option--highlight {
    background-color: $red-color;
  }
  .multiselect__option--highlight:after {
    background-color: $red-darker-color;
  }
}
.multiselect__tag {
  margin: 5px;
  font-size: $medium-font-size;
  border-left: 4px solid $purple-color;
  color: $black-grey-color !important;
  font-weight: 400;
  background: $white-grey-color !important;
  .multiselect__tag-icon:hover {
    color: $black-color !important;
    background: $grey-color !important;
  }
  .multiselect__tag-icon,
  .multiselect__tag-icon:after {
    color: $black-grey-color !important;
  }
}

#create,
#invite {
  .modal-dialog {
    width: 95vw;
  }
}

.pagination {
  .page-link {
    color: $red-color;
    text-decoration: none;
  }
  .page-item.active .page-link {
    background-color: $red-color;
    border-color: $red-color;
    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.container {
  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.icon-link {
  text-align: center;
  color: $black-grey-color;
  -webkit-transition: color 0.3s; /* Safari */
  transition: color 0.3s;
}
.icon-link:hover {
  cursor: pointer;
  color: $black-color;
}

.page-root {
  text-align: center;
  padding: 70px;
  margin-left: 235px;
  overflow-x: hidden;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px;
    margin-left: 0px;
  }
}

.break {
  color: $white-color;
  margin-top: 10px;
  margin-bottom: 10px;
}

.loading {
  color: $red-color;
  text-align: center;
  .inner {
    width: 50%;
    margin: 0 auto;
  }
}
.info-loading {
  color: $red-color;
}

@keyframes loading {
  0% {
  }
  25% {
  }
  50% {
    filter: blur(2px);
    transform: rotate(360deg);
  }
  75% {
  }
  100% {
    transform: rotate(-360deg);
  }
}

.animate-logo {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading-container-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-container {
  min-height: 100px;
}

.modal-header {
  border-bottom: none !important;
}

// Breaks

.break-margin {
  &-top {
    margin-top: 0px !important;
  }
  &-bottom {
    margin-bottom: 0px !important;
  }
  &-left {
    margin-left: 0px !important;
  }
  &-right {
    margin-right: 0px !important;
  }
  &-all {
    margin: 0px !important;
  }
}

.break-padding {
  padding: 0px !important;
  &-top {
    padding-top: 0px !important;
  }
  &-bottom {
    padding-bottom: 0px !important;
  }
  &-left {
    padding-left: 0px !important;
  }
  &-right {
    padding-right: 0px !important;
  }
  &-all {
    padding: 0px !important;
  }
}

// Table
.vgt-wrap {
  position: initial !important;
}
.vgt-inner-wrap {
  table.striped {
    border: none;
    thead {
      font-size: 14px;
      th {
        background: $white-color;
        cursor: pointer;
        border: none;
        padding: 16px 24px;
        vertical-align: middle;
      }
    }
    tbody {
      font-size: 12px;
      tr {
        &:nth-of-type(odd) {
          background-color: rgba(241, 174, 171, 0.08);
        }
        &:hover {
          background-color: $grey-color;
        }
        td {
          padding: 7px 24px 4px 24px;
          vertical-align: middle;
          border: none;
          p {
            margin: 2px 0;
          }
        }
      }
    }
  }
  .vgt-responsive {
    box-shadow: 0 4px 15px 0 rgba(221, 221, 221, 0.8);
    border-radius: 10px;
  }
  .vgt-wrap__actions-footer {
    border: none;
  }
  .vgt-wrap__footer {
    margin-top: 15px;
    border: none;
    background: $white-color;
    .footer__row-count__select {
      color: $red-color;
    }
    .footer__navigation__page-btn {
      .chevron {
        &.right::after {
          border-left: 6px solid $red-color;
        }
        &.left::after {
          border-right: 6px solid $red-color;
        }
      }
    }
  }
}

.field-container {
  word-wrap: break-word;
  min-height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  .field-data {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    &-full {
      display: unset;
      -webkit-line-clamp: none;
      -webkit-box-orient: none;
      overflow: none;
    }
    &:after {
      display: none;
    }
  }
}

#userMenu .dropdown-toggle.nav-link {
  padding: 0 !important;
}

.cross-element {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5em;
  .deleting-cross {
    svg {
      height: 25px;
      width: 25px;
      background-color: rgba($red-lighter-color, 0.8);
      border-radius: 100%;
      color: $red-color;
      font-size: 20px;
      padding: 3px;
      cursor: pointer;
    }
  }
}

.danger-message {
  color: $red-danger;
  font-size: 14px;
}

.vgt-inner-wrap .vgt-wrap__footer {
  margin-top: 15px;
  border: none;
  background: rgb(255, 251, 251);
}

.vertical-separator {
  border-left: 1px solid $white-grey-color;
  border-right: 1px solid $white-grey-color;
  margin: 0 20px;
}
</style>
