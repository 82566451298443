<template>
  <aside>
    <a class="nav-item nav-link navbar-brand" href="#">
      <div class="navbar-circle rounded-circle">
        <div class="w-100 navbar-logo">
          <img
            id="logo"
            class="d-block mx-auto img-fluid"
            src="@/ort-lib/assets/images/CORE_LOGO.png"
            alt="Core logo"
            width="40"
            height="40"
          />
        </div>
      </div>
    </a>
    <div class="sidebar-wrapper">
      <ul class="navbar-nav">
        <router-link :to="{ name: 'Dashboard' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Dashboard') ? 'active' : '')"
            @click="focusSection('Dashboard')"
          >
            <div class="sidebar-item">
              <img :src="dashboardIcon" alt="Dashboard Icon">
              <span class="navbar-section">Dashboard</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Users' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Users') ? 'active' : '')"
            @click="focusSection('Users')"
          >
            <div class="sidebar-item">
              <img :src="usersIcon" alt="users">
              <span class="navbar-section">User access</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Tools' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Tools') ? 'active' : '')"
            @click="focusSection('Tools')"
          >
            <div class="sidebar-item">
              <img :src="toolsIcon" alt="tools">
              <span class="navbar-section">Tools</span>
            </div>
          </div>
        </router-link>
        <div class="item">
          <hr class="m-1">
          <div class="sidebar-title">
            Finances
          </div>
        </div>
        <router-link v-if="getters.isOrtFinance" :to="{ name: 'Bills' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Bills') ? 'active' : '')"
            @click="focusSection('Bills')"
          >
            <div class="sidebar-item">
              <img :src="walletIcon" alt="wallet icon">
              <span class="navbar-section">Bills</span>
            </div>
          </div>
        </router-link>
        <router-link v-if="getters.isOrtFinance" :to="{ name: 'Payouts' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Payouts') ? 'active' : '')"
            @click="focusSection('Payouts')"
          >
            <div class="sidebar-item">
              <img :src="payoutIcon" alt="payout icon">
              <span class="navbar-section">Payouts</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Valuations' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Valuations') ? 'active' : '')"
            @click="focusSection('Valuations')"
          >
            <div class="sidebar-item">
              <img :src="iconCoinsHand" alt="Icon Coins Hand">
              <span class="navbar-section">Valuations</span>
            </div>
          </div>
        </router-link>
        <div class="item">
          <hr class="m-1">
          <div class="sidebar-title">
            Investors
          </div>
        </div>
        <router-link :to="{}" class="item">
          <div
            :class="'sidebar-section '"
            @click="toggleSubsection('clients')"
          >
            <div class="sidebar-item">
              <img :src="investorsIcon" alt="Clients Icon">
              <span class="navbar-section">Clients</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'ClientsDashboard' }" class="item" v-if="subsectionActive.clients">
          <div
            :class="'sidebar-section ' + (isActive('ClientsDashboard') ? 'active' : '')"
            @click="focusSection('ClientsDashboard')"
          >
            <div class="sidebar-item">
              <span class="navbar-section subsection">Dashboard</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Investors' }" class="item" v-if="subsectionActive.clients">
          <div
            :class="'sidebar-section ' + (isActive('Investors') ? 'active' : '')"
            @click="focusSection('Investors')"
          >
            <div class="sidebar-item">
              <span class="navbar-section subsection">Investors</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Partners' }" class="item" v-if="subsectionActive.clients">
          <div
            :class="'sidebar-section ' + (isActive('Partners') ? 'active' : '')"
            @click="focusSection('Partners')"
          >
            <div class="sidebar-item">
              <span class="navbar-section subsection">Partners</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Investments' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Investments') ? 'active' : '')"
            @click="focusSection('Investments')"
          >
            <div class="sidebar-item">
              <img :src="pieChartIcon" alt="PieChart">
              <span class="navbar-section">Investments</span>
            </div>
          </div>
          <hr class="m-1">
        </router-link>
        <div class="item">
          <div class="sidebar-title">
            Dealflow & Portfolio
          </div>
        </div>
        <router-link :to="{ name: 'Startups' }" class="item">
          <div
            :class="'sidebar-section ' + (isActive('Startups') ? 'active' : '')"
            @click="focusSection('Startups')"
          >
            <img :src="rocketIcon" alt="rocket">
            <span class="navbar-section">Startups</span>
          </div>
        </router-link>
        <router-link :to="{}" class="item">
          <div
            :class="'sidebar-section '"
            @click="toggleSubsection('fundraisings')"
          >
            <div class="sidebar-item">
              <img :src="lineChartUpIcon" alt="Line Chart Up">
              <span class="navbar-section">Fundraisings</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'Fundraisings' }" class="item" v-if="subsectionActive.fundraisings">
          <div
            :class="'sidebar-section ' + (isActive('Fundraisings') && ($route.path === '/fundraisings') ? 'active' : '')"
            @click="focusSection('Fundraisings')"
          >
            <div class="sidebar-item">
              <span class="navbar-section subsection">Club Deals</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/fundraisings/5786' }" class="item" v-if="subsectionActive.fundraisings">
          <div
            :class="'sidebar-section ' + ($route.path === '/fundraisings/5786' ? 'active' : '')"
            @click="focusSection('Paragon')"
          >
            <div class="sidebar-item">
              <span class="navbar-section subsection">Paragon</span>
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/fundraisings/5785' }" class="item" v-if="subsectionActive.fundraisings">
          <div
            :class="'sidebar-section ' + ($route.path === '/fundraisings/5785' ? 'active' : '')"
            @click="focusSection('Fundraisings')"
          >
            <div class="sidebar-item">
              <span class="navbar-section subsection">Rhapsody II</span>
            </div>
          </div>
        </router-link>
      </ul>
    </div>
    <div class="user-wrapper">
      <hr class="mt-0"/>
      <ORTDropdown class="user-dropdown dropdown"
        :options="userOptions"
        @input="chooseOption"
      >
        <template #dropdownButton>
          <div class="sidebar-item user-info">
            <span class="name">{{ truncate(this.name, 15) }}</span>
            <span class="email">{{ truncate(this.email, 20) }}</span>
          </div>
        </template>
      </ORTDropdown>
    </div>
  </aside>
</template>

<script>
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Getters} JwtGetters */
/** @typedef {import("src/ort-lib/stores/accounts/jwt.js").Actions} JwtActions */

import { getAction, getGetter } from '@/utils/jsdoc.js';
import { truncate } from '@/utils/formatters.js';
import ORTDropdown from '@/ort-lib/components/ORTDropdown.vue';

export default {
  name: 'sidebar',
  components: {
    ORTDropdown,
  },
  data() {
    return {
      is_toggled: false,
      actions: {
        removeTokens: getAction(/** @type {JwtActions["removeTokens"]} */ ('removeTokens')),
      },
      subsectionActive: {
        clients: false,
        fundraisings: false,
      },
      dashboardIcon: new URL('@/ort-lib/assets/icons/barChartSquare.svg', import.meta.url).href,
      toolIcon: new URL('@/ort-lib/assets/icons/tool-01.svg', import.meta.url).href,
      iconCoinsHand: new URL('@/ort-lib/assets/icons/coins-hand.svg', import.meta.url).href,
      lineChartUpIcon: new URL('@/ort-lib/assets/icons/line-chart-up-02.svg', import.meta.url).href,
      pieChartIcon: new URL('@/ort-lib/assets/icons/pie-chart-03.svg', import.meta.url).href,
      rocketIcon: new URL('@/ort-lib/assets/icons/rocket-02.svg', import.meta.url).href,
      usersIcon: new URL('@/ort-lib/assets/icons/users-01.svg', import.meta.url).href,
      partnersIcon: new URL('@/ort-lib/assets/icons/building-05.svg', import.meta.url).href,
      investorsIcon: new URL('@/ort-lib/assets/icons/user-plus-01.svg', import.meta.url).href,
      walletIcon: new URL('@/ort-lib/assets/icons/wallet-03.svg', import.meta.url).href,
      toolsIcon: new URL('@/ort-lib/assets/icons/tool-01.svg', import.meta.url).href,
      payoutIcon: new URL('@/ort-lib/assets/icons/log-out-01.svg', import.meta.url).href,
    };
  },
  computed: {
    getters() {
      return {
        isOrtFinance: getGetter(/** @type {JwtGetters["isOrtFinance"]} */ ('isOrtFinance')),
        accesstokenPayload: getGetter(/** @type {JwtGetters["accesstokenPayload"]} */ ('accesstokenPayload')),
      };
    },
    email() {
      return this.$store.state.email;
    },
    name() {
      return this.$store.state.name || 'My user';
    },
    userOptions() {
      return [
        {
          label: 'Sign out',
          value: 'sign_out',
        },
        {
          label: 'Settings',
          value: 'settings',
        },
        {
          label: 'My profile',
          value: 'my_profile',
        },
      ];
    },
  },
  methods: {
    truncate,
    logout() {
      this.actions.removeTokens();
    },
    isActive(section) {
      return this.$store.state.section_focused === section
    },
    focusSection(section) {
      this.$store.dispatch('focusSection', section);
    },
    chooseOption(value) {
      if (value == 'sign_out') {
        this.logout();
      } else if (value == 'settings') {
        this.$router.push({ name: 'ProfileSettings' });
      } else if (value == 'my_profile') {
        const userId = this.getters.accesstokenPayload.user_id;
        this.$router.push({ name: 'CustomUser', params: { id: userId } });
      }
    },
    toggleSubsection(subsection) {
      this.subsectionActive[subsection] = !this.subsectionActive[subsection];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/css/global.scss';

.user-dropdown {
  cursor: pointer;
  :deep() .ort-dropdown {
    border: none;
    box-shadow: none;
  }
  :deep() .ort-dropdown:focus {
    box-shadow: none;
  }
  #user-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navbar-logo {
  position: sticky;
  top: 25px;
}
.navbar-brand {
  @extend .text-lg;
  padding: 0;
  margin: 0;
  height: 115px;
  width: 100%;
  background-color: $white-color !important;
  color: $white-color !important;
  cursor: default;
}
.navbar-circle {
  position: relative;
  width: 700px;
  height: 710px;
  left: -265px;
  top: -618px;
  background: linear-gradient(289.5deg, #EF6861 0%, #FFBEBD 100%);
}

.dropdown {
  .dropdown-menu {
    a {
      text-decoration: none;
      outline: none;
    }
    #profile-picture {
      border: 1px solid $white-color;
    }
  }
}

.navbar-section {
  font-size: 14px;
  margin-left: 10px;
  color: black;
  flex-grow: 1;
}
.subsection {
  margin-left: 34px;
}
aside {
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow-x: clip;
  border-right: 1px solid $white-grey-color;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  router-link {
    display: block;
    padding: 10px 5px;
    color: $grey-color;
  }
  .sidebar-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .user-wrapper {
    padding-bottom: 20px;
    margin-top: auto;
  }
}

.hr-sidebar {
  margin-left: 10px;
  margin-bottom: 10px;
  border: 0.01px solid $white-grey-color;
  width: 70%
}
.sidebar-section {
  @include make-flex(center, center, auto);
  width: 100%;
  color: $black-grey-color;
  background-color: $white-color;
  box-sizing: border-box;
  justify-content: flex-start ;
  display: flex !important;
  flex-grow: 1 !important;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;

  &.active {
    color: $red-color;
    background-color: rgba($red-color, 0.1);
  }
  &:hover {
    background-color: $white-grey-color;
  }
  .sidebar-item {
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-grow:1;
  }
}
.sidebar-title {
  padding-left: 12px;
  padding-bottom: 8px;
  @extend .text-sm--semibold;
  color: $grey-300 !important;
}

.user-info{
  @extend .text-lg--bold;
  color: $black-color;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  .email{
     @extend .text-sm--regular;
     margin-bottom: 15px;
  }
}

.item {
  @extend .text-md--semibold;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  a {
    padding: 0px;
    display: flex;
    flex-grow:1;
    padding-right: 20px;
  }

  &:hover {
    text-decoration: none;
  }

}
:deep(){
  .dropdown-toggle::after {
    display: none;
  }
}

.user-circle {
    @include make-flex(center, center, auto);
    width: 30px;
    height: 30px;
    font-size: 15px;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 $grey-color;
    background-color: rgba($black-color, 0.2);
    color: $black-grey-color;
  }
</style>
