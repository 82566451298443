export default {
  apiInfos(state) {
    return state.api;
  },
  expiry(state) {
    return state.expiry;
  },
  section_focused(state) {
    return state.section_focused;
  },
};
