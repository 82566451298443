import { axiosCore } from '@/plugins/axios';

const state = {
  isBillTableLoading: false,
  allBills: [],
  allBillsCount: 0,
  currentBill: {},
};

const getters = {
  currentBill: (state) => state.currentBill,
};

const actions = {
  async fetchAllBills({ commit, dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    commit('setIsBillTableLoading', true);
    try {
      const response = await axiosCore.get(`bills/?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}`);
      commit('setAllBills', response.data?.results);
      commit('setAllBillsCount', response.data?.count);
      const noBillResponse = await axiosCore.get(`bills/no-bill`);
      for (const r in noBillResponse.data) {
        commit('concatAllBills', noBillResponse.data[r]);
      }
    } catch {
      dispatch('dangerAlert', 'There was an error getting data');
    } finally {
      commit('setIsBillTableLoading', false);
    }
  },
  async exportExcelBills({ dispatch }, {
    perPage, page, searchParam, selectedFilters, order,
  }) {
    try {
      const response = await axiosCore.get(`bills/?${searchParam}page=${page}&page_size=${perPage}${selectedFilters}${order}&format=csv`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    } catch {
      dispatch('dangerAlert', 'There was an error downloading the finance table data');
    }
  },
  async updateSingleBill({ dispatch }, billData) {
    const billId = billData.id;
    delete billData.id;
    try {
      const response = await axiosCore.patch(`bills/${billId}/update`, billData);
      dispatch('successAlert', 'Bill updated');
      return response.data;
    } catch {
      dispatch('dangerAlert', 'There was an error when the request was performed');
    }
  },
  async fetchBillPreview({ dispatch }, billId) {
    try {
      const response = await axiosCore.patch(`bills/${billId}/preview`);
      return response.data;
    } catch {
      dispatch('dangerAlert', 'There was an error getting the preview');
    }
  },
  async deleteBill({ dispatch }, billId) {
    try {
      await axiosCore.delete(`bills/${billId}`);
      dispatch('successAlert', 'Bill deleted successfully');
    } catch {
      dispatch('dangerAlert', 'There was an error trying to delete the bill');
    }
  },
};

const mutations = {
  setIsBillTableLoading: (state, data) => state.isBillTableLoading = data,
  setAllBillsCount: (state, data) => state.allBillsCount = data,
  setAllBills: (state, data) => state.allBills = data,
  concatAllBills: (state, data) => state.allBills.unshift(data),
  setCurrentBill: (state, data) => state.currentBill = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
