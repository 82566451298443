<template>
  <div class="wrapper">
    <div class="label" v-if="label">
      <span v-if="required" class="text required-field-asterisc">* </span>
      <span :class="'ort-dropdown__label text-' + labelSize + labelStyle">{{ label }}</span>
    </div>
    <div
      v-if="!showingTag"
      class="ort-dropdown"
      :class="{
        'ort-dropdown--disabled': isDropdownDisabled
      }"
      :tabindex="tabindex"
    >
    <VDropdown
      :distance="18"
      placement="bottom"
      @hide="hideOptions"
      :shown="isOptionsBlockOpened"
    >
      <div
        v-if="selected && !this.$slots.dropdownButton"
        class="ort-dropdown--selected"
        :class="{
          'ort-dropdown--disabled': isDropdownDisabled
        }"
        @click="showOptions"
      >
        {{ selected }}
        <!-- <transition name="fade">
          <div v-if="!isOptionsBlockOpened"><i class="fas fa-chevron-down"></i></div>
          <div v-else><i class="fas fa-chevron-up"></i></div>
          <ChevronVertical />
        </transition> -->
        <img :src="chevronVertical" alt="Chevron Vertical">
      </div>
      <slot v-else name="dropdownButton">
        <div
          class="ort-dropdown--unselected ort-dropdown__placeholder"
          :class="{
            'ort-dropdown--disabled': isDropdownDisabled
          }"
          @click="showOptions"
        >
          {{ placeholder }}
          <!-- <transition name="fade">
            <div v-if="!isOptionsBlockOpened"><i class="fas fa-chevron-down"></i></div>
            <div v-else><i class="fas fa-chevron-up"></i></div>
          </transition> -->
          <img :src="chevronVertical" alt="Chevron Vertical">
        </div>
      </slot>
      <template #popper>
        <div
          class="ort-dropdown__options"
          v-close-popper
          v-if="!isDropdownDisabled"
        >
          <div
            class="ort-dropdown__option-item"
            v-for="option of options"
            :key="option.value"
            @click="selectOption(option)"
          >
            {{ option.label || option.text }}
          </div>
        </div>
      </template>
    </VDropdown>
    </div>
    <div class="tag" v-if="isDropdownDisabled && showingTag">
      <div v-if="!!this.selected">
        <ORTBadge
          :text="selected"
          :color="tagColor"
        />
      </div>
      <div v-else class="text-md no-info">
        no info
      </div>
    </div>
    <span class="ort-dropdown__hint-text">{{ hintText }}</span>
  </div>
</template>

<script>
/** @template T; @typedef {import("vue").PropType<T>} PropType<T> */

import ORTBadge from '@/ort-lib/components/ORTBadge.vue';

export default {
  name: 'ORTDropdown',
  emits: ['input'],
  components: {
    ORTBadge,
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    labelSize: {
      type: String,
      default: 'sm--',
    },
    labelStyle: {
      type: String,
      default: 'semibold',
    },
    options: {
      /** @type {PropType<[{ "value": "", "text|label": ""}]>} */
      type: Array,
      default: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    hintText: {
      type: String,
      default: '',
    },
    showingTag: {
      type: Boolean,
      default: false,
    },
    isDropdownDisabled: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      required: false,
    },
    tagColor: {
      type: String,
      required: false,
      default: 'purple',
    },
    optionsHeight: {
      type: String,
      required: false,
      default: '180px',
    },
    showOptionsBlock: {
      required: null,
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      internalValue: this.value,
      isOptionsBlockOpened: this.showOptionsBlock,
      chevronVertical: new URL('@/assets/icons/chevron-selector-vertical.svg', import.meta.url).href,
    };
  },
  mounted() {
    this.internalValue = this.value;
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
  },
  computed: {
    cssStyles() {
      return {
        '--optionsHeight': this.optionsHeight || '180px',
      };
    },
    selected() {
      const choice = this.options?.find((option) => option.value === this.internalValue);
      if (choice) return choice.label || choice.text;
      return '';
    },
  },
  methods: {
    selectOption(option) {
      this.internalValue = option.value;
      this.isOptionsBlockOpened = null;
      this.$emit('input', option.value, this.field, this.parent, this.required, !!option.value, option.text);
    },
    showOptions() {
      this.isOptionsBlockOpened = true;
    },
    hideOptions() {
      this.isOptionsBlockOpened = null;
    },
    isSelected(option) {
      return option.label == this.selected || option.text == this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.ort-dropdown {
  @extend .text-md--medium;
  width: 100%;
  min-width: 120px;
  text-align: left;
  outline: none;
  height: 47px;
  padding: 0;
  background: $white-color;
  border: 1px solid $grey-500;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;

  .check-icon {
    visibility: hidden;
    background-color: $red-200 !important;
  }

  &:focus {
    border: 1px solid $red-400;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $red-500;
  }

  &__label {
    color: $black-color;
    margin-bottom: 6px;
  }

  &__hint-text {
    @extend .text-sm--medium;
    color: $grey-200;
    margin-top: 8px;
  }

  &--selected,
  &--unselected {
    background-color: $white-color;
    border-radius: 12px;
    color: $grey-100;
    cursor: pointer;
    user-select: none;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  &--unselected {
    color: $grey-200;
  }

  &__options {
    &:last-child {
      margin-bottom: 0;
    }
    height:auto;
    max-height:50vh;
    overflow: auto;
    padding: 6px;
    background-color: $white-color;
    min-width: 190px;
    border: 1px solid grey-600;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
  }

  &__option-item {
    color: $grey-100;
    padding-left: 8px;
    cursor: pointer;
    user-select: none;
    height: 44px;
    padding: 10px 8px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;

    &:hover {
      background: $red-500;
      border-radius: 12px;
      color: $grey-100;

      .check-icon {
        visibility: visible;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: $grey-700;
    color: $grey-400;
    border-color: $grey-400;

    .ort-dropdown {
      background-color: $grey-500;
    }

    .ort-icon {
      background-color: $grey-400 !important;
    }
  }
}
.tag {
  margin: 5px;
}
.required-field-asterisc {
  color: $red-color;
}

.label {
  padding-bottom: 10px;
  color: $grey-100;
  white-space: nowrap;
}
.no-info {
  font-style: italic;
  color: $grey-300;
}
</style>
