export default {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  name: null,
  phone: null,
  profile_image: null,
  groups: [],
  section_focused: null,
  platform_domain: import.meta.env.PLATFORM_DOMAIN,
  api: {
    domain: import.meta.env.VITE_API_CORE,
    csrftoken: null,
    token: null,
  },
  logged_in: null,
  expiry: {
    cookie: 30, // 30 days
    local_storage: 60 * 60 * 1000 * 24 * 30, // 30 days
  },
  alert: {
    show: false,
    message: '',
    type: 'info',
  },
  cache_data: {},
  locale: 'en',
};
